<template>
  <v-app>
    <v-layout justify-center v-if="islogged()" fill-height>
      <v-flex
        hidden-md-and-down
        xs6
        class="d-flex align-center justify-center global-bg"
      >
      </v-flex>

      <v-flex lg6 md6 sm12 class="d-flex justify-center align-center imageleft">
        <v-card flat class="pa-4 global-form d-flex flex-column justify-center">
          <div class="d-flex justify-center mb-5">
            <v-img class="global-logoimg" src="@/assets/logo-mclick-min.png">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-1"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </div>

          <v-form ref="login" @keyup.enter.native="Login()">
            <v-card flat class="d-flex flex-column justify-center">
              <!-- USERNAME FIELD -->
              <v-text-field
                v-model="text_user.text"
                v-mask="rutMask"
                persistent-placeholder
                :label="text_user.label"
                :rules="text_user.rules"
                max_length="12"
                :placeholder="text_user.placeholder"
                outlined
                id="id_username"
                name="username"
                autocomplete="username"
                :error-messages="text_user.error"
              ></v-text-field>

              <!-- PASSWORD FIELD -->
              <v-text-field
                persistent-placeholder
                v-model="text_password.text"
                :label="text_password.label"
                :type="text_password.type"
                :append-icon="
                  text_password.type == 'password' ? 'mdi-eye-off' : 'mdi-eye'
                "
                @click:append="
                  text_password.type == 'password'
                    ? (text_password.type = 'text')
                    : (text_password.type = 'password')
                "
                :placeholder="text_password.placeholder"
                :rules="text_password.rules"
                outlined
                :error-messages="text_password.error"
                id="id_password"
                name="password"
                autocomplete="password"
              ></v-text-field>
            </v-card>
          </v-form>

          <div class="text-center mb-5">
            <router-link to="/recover-password"
              >¿Olvidaste tu contraseña?</router-link
            >
          </div>

          <v-btn color="primary" :loading="isloading" @click="Login()"
            >Acceder <v-icon right>mdi-login</v-icon></v-btn
          >

          <v-slide-y-transition>
            <v-alert
              v-if="error"
              border="left"
              colored-border
              type="error"
              elevation="2"
              class="mt-3"
            >
              {{ errortext || "Ha ocurrido un error." }}
            </v-alert>
          </v-slide-y-transition>
        </v-card>
      </v-flex>
    </v-layout>
  </v-app>
</template>
<script>
import { rutMask } from "@/store/resources/masks.js";
import { validarRutFormat, validarRut } from "@/store/resources/validate.js";

export default {
  name: "Login",
  data: () => ({
    rut: "",
    error: false,
    errortext: "",
    isloading: false,
    text_password: {
      label: "Contraseña",
      type: "password",
      placeholder: "Ingrese su contraseña",
      text: "",
      error: "",
      rules: [(value) => !!value || "La contraseña es un campo requerido."],
    },
    text_user: {
      label: "RUT de Usuario",
      placeholder: "Ingrese el RUT de Usuario",
      text: "",
      error: "",
      rules: [
        (value) => !!value || "Usuario es un campo requerido.",
        (v) => validarRutFormat(v) || "Formato de RUT invalido",
        (v) => validarRut(v) || "No se ha logrado reconocer el RUT",
      ],
    },
  }),
  computed: {
    rutMask: () => rutMask,
  },
  methods: {
    islogged() {
      if (this.$store.getters.isLoggedIn) {
        this.$router.push({ name: "Home" });
        return false;
      }
      return true;
    },
    Login() {
      this.error = false;

      if (this.$refs.login.validate()) {
        const user = this.text_user.text.replace(/[^A-Z0-9]/gi, "");
        this.isloading = true;
        this.$store
          .dispatch("login", {
            username: user,
            password: this.text_password.text,
          })
          .then((response) => {
            if (response.userData.cambiar_clave) {
              this.$store.commit("SET_CLAVE_TEMPORAL", this.text_password.text);
              this.$store.commit("SET_TOKEN_TEMPORAL", response.token);
              this.$router.push({ name: "NewPassword" });
            } else {
              this.$router.push({ name: "Home" });
            }
          })
          .catch((e) => {
            this.error = true;
            this.errortext = e;
          })
          .finally(() => (this.isloading = false));
      }
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,400;1,300&display=swap");
@import "../../assets/CSS/Theme.css";
@import "../../assets/CSS/ServiceStyle.css";
v-router-link,
li,
a {
  text-decoration: none;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
}
</style>
